import React from "react";
import "../styles/battery.css";
import battery from "../assets/battery.webp";
import powerbank from "../assets/power-bank.webp";
import {useScrollTriggerAnimation} from "../hooks/useScrollTriggerAnimation";

const Battery = () => {

  const animFrom = { x: -200, opacity: 0 };
  const animTo = { x: 0, opacity: 1 };

  const ref = useScrollTriggerAnimation({
    from: animFrom,
    to: animTo
  });

  return (
      <div ref={ref} className="comp-battery" id="batteries">
        <div className="main-title">BATTERIES</div>
        <div className="battery">
          <div className="image-gallery">
            <div className="image-card">
              <img src={battery} alt="Im1" />
              <h3>Batterie au lithium</h3>
              <p>
                Batterie au lithium fer phosphate de grande capacité, sûre et
                longue durée de vie avec système BMS.
              </p>
            </div>
            <div className="image-card">
              <img src={powerbank} alt="Im2" />
              <h3>Power Bank</h3>
              <p>
                Centrale solaire de générateur portable UPS utilisant des
                batteries lithium-ion rechargeables.
              </p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Battery;
