import React, { useState, useEffect, useRef, useCallback } from "react";
import { Circle, CircleDot } from "lucide-react";
import "../styles/imageslider.css";

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const timerRef = useRef(null);

  // Show the next image
  const showNextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === images.length - 1) return 0;
      return prevIndex + 1;
    });
  }, [images.length]);

  // Start the timer
  const startTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      showNextImage();
    }, 3000); // Change image every 3 seconds
  }, [showNextImage]);

  useEffect(() => {
    startTimer();
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [images, startTimer]);

  // Handle dot click
  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
    startTimer();
  };

  return (
    <section
      aria-label="Image Slider"
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          overflow: "hidden",
        }}
      >
        {images.map(({ url, alt }, index) => (
          <img
            key={url + index}
            src={url}
            alt={alt}
            aria-hidden={currentImageIndex !== index}
            className="image-slider-image"
            style={{ transform: `translateX(-${100 * currentImageIndex}%)` }}
          />
        ))}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: ".5rem",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: ".25rem",
        }}
      >
        {images.map((_, index) => (
          <button
            key={index}
            className={`image-slider-dot-button ${
              index === currentImageIndex ? "active" : ""
            }`}
            aria-label={`View Image ${index + 1}`}
            onClick={() => handleDotClick(index)}
          >
            {index === currentImageIndex ? (
              <CircleDot style={{ fill: "green" }} aria-hidden />
            ) : (
              <Circle aria-hidden />
            )}
          </button>
        ))}
      </div>
    </section>
  );
};

export default ImageSlider;
