import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import "../styles/cardetailstable.css";
import { carDetailsLabels } from "../assets/utils/carDetailsTableLabels";

export const CarDetailsTable = ({ car }) => {
  const renderRow = (category, details) => {
    return details.map((detail, index) => (
      <tr key={detail}>
        {index === 0 && (
          <td
            style={{
              backgroundColor: "#f8f9fa",
              verticalAlign: "middle",
              textAlign: "center",
            }}
            rowSpan={details.length}
          >
            {category}
          </td>
        )}
        <td>{carDetailsLabels[detail]}</td>
        <td>{car.carDetails[detail]}</td>
      </tr>
    ));
  };

  return (
    <Container className="my-4">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <Table responsive bordered className="custom-table">
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "#f8f9fa",
                    verticalAlign: "center",
                  }}
                  colSpan="3"
                >
                  Configuration Standard
                </th>
              </tr>
            </thead>
            <tbody>
              {renderRow("paramètre", [
                "dimensions",
                "maxAutonomy",
                "capacity",
                "loadingCapacity",
              ])}
              {renderRow("Système électrique", [
                "motor",
                "battery",
                "chargingTime",
              ])}
              {renderRow("Système de freinage", [
                "brakingSystemType",
                "frontBrake",
                "rearBrake",
              ])}
              {renderRow("Système de suspension", [
                "frontSuspension",
                "rearSuspension",
              ])}
              {renderRow("Suspension de roue", ["tire", "wheelHub"])}
              {renderRow("Dispositif fonctionnel", [
                "multimedia",
                "electricHeater",
                "centralLock",
                "oneButtonStart",
                "electricDoorAndWindow",
                "interior",
              ])}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
