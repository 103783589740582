import React, { useEffect, useState } from "react";
import "../styles/cardetails.css";

export const HomePageCover = () => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1500);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 1300);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={'cover-image-div'} style={{
            background: 'url("/cover/home-cover.png") no-repeat center center fixed',
            backgroundSize: isLargeScreen ? '100% 80%' : 'auto'
        }}>
        </div>
    );
}
