import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Compartement from "../components/Compartement";
import Services from "../components/Services";
import Portfolio from "../components/Portfolio";
import Battery from "../components/Battery";
import Landing from "../components/Landing";
import ContactUs from "../components/ContactUs";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";
import InfiniteLooper from "../components/InfiniteLooper";
import {HomePageCover} from "../components/HomePageCover";

function Homepage() {
  const location = useLocation();
  const elementId = location.hash.substring(1); // remove the '#' at the start

  useEffect(() => {
    if (elementId) {
      if (elementId === "contact") {
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        const element = document.getElementById(elementId);
        if (element) {
          window.scroll({
            top: element.offsetTop,
            behavior: "smooth",
          });
        }
      }
    }
  }, [elementId]);

  return (
    <>
      <Navbar />
        <HomePageCover />
      <Landing id="home" />
      <Compartement />

        <InfiniteLooper speed={14} direction={"left"}>
            <img style={{width: "150px"}} src={"/models/j1/1.webp"} alt={"j1"}/>
            <img style={{width: "150px"}} src={"/models/j1c/1.webp"} alt={"j1c"}/>
            <img style={{width: "150px"}} src={"/models/j1d/1.webp"} alt={"j1d"}/>
            <img style={{width: "150px"}} src={"/models/j2/1.webp"} alt={"j2"}/>
            <img style={{width: "150px"}} src={"/models/j2c/1.webp"} alt={"j2x"}/>
            <img style={{width: "150px"}} src={"/models/j2d/1.webp"} alt={"j2d"}/>
        </InfiniteLooper>
      <Portfolio id="modeles" />
      <Battery id="batteries" />
      <Services id="services" />
      <ContactUs id="contact" />
      <Footer />
    </>
  );
}

export default Homepage;
