import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../styles/navbar.css";

const MenuItem = ({ to, label }) => {
  return (
    <li className="menu-item">
      <Link className="nav-link" to={to}>
        {label}
      </Link>
    </li>
  );
};
export const NavBarOnlyWithLogoAndPrevButton = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const goBack = () => {
    navigate(-1);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="navbar navbar-light bg-light">
      <div className="container-fluid">
        <button className="btn btn-secondary me-2" onClick={goBack}>
          Back
        </button>
        <Link className="logo" to="/">
          <img src="/logo512.png" alt="Company logo" />
        </Link>
        <button className="navbar-toggler" type="button" onClick={toggleMenu}>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`overlay ${ menuOpen ? "active" : "" }`}
          onClick={toggleMenu}
        >
        </div>
        <div className="close-button" onClick={toggleMenu}>X</div>
        <div className={`menu ${ menuOpen ? "active" : "" }`}>
          <ul className="menu-items">
            <MenuItem onClick={() => {toggleMenu()}} to="/#home" label="Home" />
            <MenuItem onClick={() => {toggleMenu()}} to="/#modeles" label="Modèles" />
            <MenuItem onClick={() => {toggleMenu()}} to="/#batteries" label="Batteries" />
            <MenuItem onClick={() => {toggleMenu()}} to="/#services" label="Services" />
            <MenuItem onClick={() => {toggleMenu()}} to="/#contact" label="Contact" />
          </ul>
        </div>
      </div>
    </header>
  );
};
