import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import "../styles/navbar.css";

const MenuItem = ({to, label, toggleMenu}) => {
    return (
        <li className="menu-item">
            <Link className="nav-link" onClick={() => {
                toggleMenu()
            }} to={to}>
                {label}
            </Link>
        </li>
    );
};

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [isMobileView, setMobileView] = useState(window.innerWidth <= 800);

    const toggleMenu = () => {
        setShowMenu(prevShowMenu => !prevShowMenu);
    };

    useEffect(() => {
        const handleResize = () => {
            setMobileView(window.innerWidth <= 800);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function scrollToSection(sectionId) {
        const section = document.querySelector(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth",
            });
        }
    }

    return (
        <header className="navbar">
            <div className="logo">
                <img src={"/models/logo-left.webp"} alt=""/>
            </div>
            {isMobileView ? (
                <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>
            ) : (
                <nav>
                    <ul>
                        <li>
                            <Link className="links" to="/" onClick={() => toggleMenu}>
                                Home
                            </Link>
                        </li>
                        <li className="models">
                            <Link
                                className="links"
                                to="#modeles"
                                onClick={() => {
                                    toggleMenu();
                                    scrollToSection("#modeles");
                                }}
                            >
                                Modèles
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="links"
                                to="#batteries"
                                onClick={() => scrollToSection("#batteries")}
                            >
                                Batteries
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="links"
                                to="#services"
                                onClick={() => scrollToSection("#services")}
                            >
                                Services
                            </Link>
                        </li>
                        <li>
                            <Link className="links" to="#">
                                Contact
                            </Link>
                        </li>
                    </ul>
                </nav>
            )}
            {isMobileView && showMenu && (
                <>
                    <div
                        className={`overlay ${showMenu ? "active" : ""}`}
                        onClick={toggleMenu}
                    >
                    </div>
                    <div className="close-button" onClick={toggleMenu}>X</div>
                    <div className={`menu ${showMenu ? "active" : ""}`}>
                        <ul className="menu-items">
                            <MenuItem toggleMenu={toggleMenu} to="/" label="Home"/>
                            <MenuItem toggleMenu={toggleMenu} to="/#modeles" label="Modèles"/>
                            <MenuItem toggleMenu={toggleMenu} to="/#batteries" label="Batteries"/>
                            <MenuItem toggleMenu={toggleMenu} to="/#services" label="Services"/>
                            <MenuItem toggleMenu={toggleMenu} to="/#contact" label="Contact"/>
                        </ul>
                    </div>
                </>
            )}
        </header>
    );
};

export default Navbar;
