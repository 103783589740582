import React from 'react';
import { NavBarOnlyWithLogoAndPrevButton } from './NavBarOnlyWithLogoAndPrevButton'
import { useParams } from "react-router-dom";

import { CarDetailsTable } from './CarDetailsTable';
import { cars } from "../assets/utils/carsObject";




const CarConfiguration = () => {
    const { carModel } = useParams();
    const car = cars.find((c) => c.model === carModel);
    const item = car.images
    return (
        <>
            <NavBarOnlyWithLogoAndPrevButton />
            <div className='d-flex mt-3 justify-content-evenly flex-wrap mt-3'>
                {item.map((it, i) => (
                        <img key={i} className='img-fluid m-2' src={it.url} alt={it.alt} style={{ maxWidth: '70px' }} />
                ))}
            </div>
                <h4 className="text-primary text-center mt-3">Model: {car.model}</h4>
            <CarDetailsTable car={car}/>
        </>
    )
}

export default CarConfiguration