import React, { useState } from 'react'

const Pra = () => {
    const [Conter, setConter] = useState(0);
    const increment = () => {
        setConter(Conter+1)
    }
    const reset = () => {
        setConter(0)
    }

    return (
        <div className='mt-5'>
            <button className='mx-5 btn btn-warning' onClick={increment}>click to Increm</button>
            <button className='btn btn-success' onClick={reset}>click to Reset</button>
            <span className='btn btn-danger mx-5 my-5'> { Conter }</span>
        </div>
    )
}

export default Pra