import React, { useEffect, useLayoutEffect, useRef } from "react";
import '../styles/landing.css'
import landing from '../assets/green-drive.webp'
import "bootstrap/dist/css/bootstrap.min.css";
import { cars } from "../assets/utils/carsObject";
import ImageSliderHome from "./ImageSliderHome";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/cardetails.css";
import {useScrollTriggerAnimation} from "../hooks/useScrollTriggerAnimation";
const Landing = () => {
    const carModel  = "LANDING";
    const car = cars.find((c) => c.model === carModel);

  // Scroll to the top of the page when the component mounts
    useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

  // Ref to track slide elements for animations
    const slideElements = useRef([]);

  // Handle element animations when they intersect
    useEffect(() => {
    let observer;
    const decideAnimationDirection = (index) =>
    index % 2 === 0 ? "slide-in-left" : "slide-in-right";

    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const targetIndex = slideElements.current.indexOf(entry.target);
            entry.target.classList.add(decideAnimationDirection(targetIndex));
            observer.unobserve(entry.target);
        }
        });
    };

    observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.5,
    });

    const slideElementsCurrent = slideElements.current; // Store the current ref value

    slideElementsCurrent.forEach((element, index) => {
        element.classList.add("not-animated");
        observer.observe(element);
    });
}, []);

    const animFrom = { x: -200, opacity: 0 };
    const animTo = { x: 0, opacity: 1 };

    const ref = useScrollTriggerAnimation({
        from: animFrom,
        to: animTo
    });

    return (
            <div className="landing" ref={ref}>
                <div className="container">
                    <Container>
                        <Row className="justify-content-md-center row-landing">
                        <Col md lg="7" className="slider-col col-landing">
                            <ImageSliderHome images={car.images} />
                        </Col>
                    </Row>
                    </Container>

                    <div className="image">
                        <img src={landing} alt="#" />
                    </div>
                </div>
                </div>
    )
};

export default Landing
