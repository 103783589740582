export const cars = [
  {
    model: "J1",
    brand: "PONI",
    category: "Véhicule électrique à trois roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j1/1.webp",
        alt: "j1",
      },
      {
        url: "/models/j1/2.webp",
        alt: "j1",
      },
      {
        url: "/models/j1/3.webp",
        alt: "j1",
      },
      {
        url: "/models/j1/4.webp",
        alt: "j1",
      },
      {
        url: "/models/j1/5.webp",
        alt: "j1",
      },
    ],
    section1: {
      title: "SYSTÈME DE CONTRÔLE ÉLECTRONIQUE",
      description:
        "UTILISEZ LE SYSTÈME DE CONTRÔLE ÉLECTRIQUE EMPOWER FIABLE ET ÉTANCHE. FREIN HYDRAULIQUE À DOUBLE CIRCUIT À DISQUE AVANT ET À TAMBOUR ARRIÈRE.",
      imgUrl: "/models/j1.png",
    },
    section2: {
      title: "TABLEAU DE BORD",
      description:
        "ÉCRAN LCD, VOLTMÈTRE, WATTMÈTRE, KILOMÉTRAGE ET CAMÉRA DE RECUL, PLUS BLUETOOTH, MP5, CONNECTEUR USB.CLIMATISEUR EN OPTION",
      imgUrl: "/models/j1.png",
    },
    carDetails: {
      dimensions: "2160*1150*1680",
      maxAutonomy: 130,
      capacity: "2-3",
      emptyWeight: 310,
      loadingCapacity: 150,
      steeringMode: "Handle",
      motor: "3000W",
      battery: "LiFePo4 105 Ah",
      chargingTime: "3 heures (chargeur rapide)",
      charger: "Smart Charger",
      brakingSystemType: "système hydraulique",
      frontBrake: "Disque",
      rearBrake: "Disque",
      frontSuspension: "Double triangulation indépendante",
      rearSuspension: "Axle arrière intégré",
      tire: "Avant  120/70-R12 Arrière 135/70-R12",
      wheelHub: "Moyeu en alliage d’aluminium",
      multimedia: "MP5 + Caméra de Recul",
      electricHeater: "60V 800W",
      centralLock: "Niveau Automatique",
      oneButtonStart: "Niveau Automatique",
      electricDoorAndWindow: 2,
      interior: "En Cuir",
    },
  },
  {
    model: "J1-C",
    brand: "PONI",
    category: "Véhicule électrique à trois roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j1c/1.webp",
        alt: "j1",
      },
      {
        url: "/models/j1c/2.webp",
        alt: "j1",
      },
      {
        url: "/models/j1c/3.webp",
        alt: "j1",
      },
    ],
    section1: {
      title: "SYSTÈME DE CONTRÔLE ÉLECTRONIQUE",
      description:
        "UTILISEZ LE SYSTÈME DE CONTRÔLE ÉLECTRIQUE EMPOWER FIABLE ET ÉTANCHE. FREIN HYDRAULIQUE À DOUBLE CIRCUIT À DISQUE AVANT ET À TAMBOUR ARRIÈRE.",
      imgUrl: "/models/j1c.png",
    },
    section2: {
      title: "TABLEAU DE BORD",
      description:
        "ÉCRAN LCD, VOLTMÈTRE, WATTMÈTRE, KILOMÉTRAGE ET CAMÉRA DE RECUL, PLUS BLUETOOTH, MP5, CONNECTEUR USB.CLIMATISEUR EN OPTION",
      imgUrl: "/models/j1c.png",
    },
    carDetails: {
      dimensions: "2715*1180*1810",
      maxAutonomy: "150/200",
      capacity: "1",
      emptyWeight: 460,
      loadingCapacity: 300,
      steeringMode: "Handle",
      motor: "3000W",
      battery: "LiFePo4 105Ah/206Ah",
      chargingTime: "2.5/5.5 heures (chargeur rapide)",
      charger: "Smart Charger",
      brakingSystemType: "système hydraulique",
      frontBrake: "Disque",
      rearBrake: "Disque",
      frontSuspension: "Double triangulation indépendante",
      rearSuspension: "Axle arrière intégré",
      tire: "Avant  120/70-R12 Arrière 145/70-R12",
      wheelHub: "Moyeu en alliage d’aluminium",
      multimedia: "MP5 + Caméra de Recul",
      electricHeater: "60V 800W",
      centralLock: "Niveau Automatique",
      oneButtonStart: "Niveau Automatique",
      electricDoorAndWindow: 2,
      interior: "En Cuir",
    },
  },
  {
    model: "J1-D",
    brand: "PONI",
    category: "Véhicule électrique à trois roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j1d/1.webp",
        alt: "j1",
      },
      {
        url: "/models/j1d/2.webp",
        alt: "j1",
      },
      {
        url: "/models/j1d/3.webp",
        alt: "j1",
      },
      {
        url: "/models/j1d/4.webp",
        alt: "j1",
      },
      {
        url: "/models/j1d/5.webp",
        alt: "j1",
      },
      {
        url: "/models/j1d/6.webp",
        alt: "j1",
      },
      {
        url: "/models/j1d/7.webp",
        alt: "j1",
      },
    ],
    section1: {
      title: "SYSTÈME DE CONTRÔLE ÉLECTRONIQUE",
      description:
        "UTILISEZ LE SYSTÈME DE CONTRÔLE ÉLECTRIQUE EMPOWER FIABLE ET ÉTANCHE. FREIN HYDRAULIQUE À DOUBLE CIRCUIT À DISQUE AVANT ET À TAMBOUR ARRIÈRE.",
      imgUrl: "/models/j1d.png",
    },
    section2: {
      title: "TABLEAU DE BORD",
      description:
        "ÉCRAN LCD, VOLTMÈTRE, WATTMÈTRE, KILOMÉTRAGE ET CAMÉRA DE RECUL, PLUS BLUETOOTH, MP5, CONNECTEUR USB.CLIMATISEUR EN OPTION",
      imgUrl: "/models/j1d.png",
    },
    carDetails: {
      dimensions: "2300*1260*1810",
      maxAutonomy: "115/200",
      capacity: "1",
      emptyWeight: 360,
      loadingCapacity: 200,
      steeringMode: "Handle",
      motor: "3000W",
      battery: "LiFePo4 105Ah/206Ah",
      chargingTime: "2.5/5.5 heures (chargeur rapide)",
      charger: "Smart Charger",
      brakingSystemType: "système hydraulique",
      frontBrake: "Disque",
      rearBrake: "Disque",
      frontSuspension: "Double triangulation indépendante",
      rearSuspension: "Axle arrière intégré",
      tire: "Avant  120/70-R12 Arrière 145/70-R12",
      wheelHub: "Moyeu en alliage d’aluminium",
      multimedia: "MP5 + Caméra de Recul",
      electricHeater: "60V 800W",
      centralLock: "Niveau Automatique",
      oneButtonStart: "Niveau Automatique",
      electricDoorAndWindow: 2,
      interior: "En Cuir",
    },
  },
  {
    model: "J2",
    brand: "PONI",
    category: "Véhicule électrique à quatre roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j2/1.webp",
        alt: "j2",
      },
      {
        url: "/models/j2/2.webp",
        alt: "j2",
      },
      {
        url: "/models/j2/3.webp",
        alt: "j2",
      },
      {
        url: "/models/j2/4.webp",
        alt: "j2",
      },
      {
        url: "/models/j2/5.webp",
        alt: "j2",
      },
      {
        url: "/models/j2/6.webp",
        alt: "j2",
      },
    ],
    section1: {
      title: "SYSTÈME DE CONTRÔLE ÉLECTRONIQUE",
      description:
        "UTILISEZ LE SYSTÈME DE CONTRÔLE ÉLECTRIQUE EMPOWER FIABLE ET ÉTANCHE. FREIN HYDRAULIQUE À DOUBLE CIRCUIT À DISQUE AVANT ET À TAMBOUR ARRIÈRE.",
      imgUrl: "/models/j2.png",
    },
    section2: {
      title: "TABLEAU DE BORD",
      description:
        "ÉCRAN LCD, VOLTMÈTRE, WATTMÈTRE, KILOMÉTRAGE ET CAMÉRA DE RECUL, PLUS BLUETOOTH, MP5, CONNECTEUR USB.CLIMATISEUR EN OPTION",
      imgUrl: "/models/j2.png",
    },
    carDetails: {
      dimensions: "2390*1200*1700",
      maxAutonomy: "90/115",
      capacity: "2/3",
      emptyWeight: 416,
      loadingCapacity: 190,
      steeringMode: "Handle",
      motor: "3000W",
      battery: "LiFePo4 105Ah",
      chargingTime: "2/3 heures (chargeur rapide)",
      charger: "Smart Charger",
      brakingSystemType: "système hydraulique",
      frontBrake: "Disque",
      rearBrake: "cylindre",
      frontSuspension: "Double triangulation indépendante",
      rearSuspension: "Axle arrière intégré",
      tire: "135/70-R12",
      wheelHub: "Moyeu en alliage d’aluminium",
      multimedia: "MP5 + Caméra de Recul",
      electricHeater: "60V 800W",
      centralLock: "Niveau Automatique",
      oneButtonStart: "Niveau Automatique",
      electricDoorAndWindow: 2,
      interior: "En Cuir",
    },
  },
  {
    model: "J2-C",
    brand: "PONI",
    category: "Véhicule électrique à quatre roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j2c/1.webp",
        alt: "j2",
      },
      {
        url: "/models/j2c/2.webp",
        alt: "j2",
      },
      {
        url: "/models/j2c/3.webp",
        alt: "j2",
      },
      {
        url: "/models/j2c/4.webp",
        alt: "j2",
      },
    ],
    section1: {
      title: "SYSTÈME DE CONTRÔLE ÉLECTRONIQUE",
      description:
        "UTILISEZ LE SYSTÈME DE CONTRÔLE ÉLECTRIQUE EMPOWER FIABLE ET ÉTANCHE. FREIN HYDRAULIQUE À DOUBLE CIRCUIT À DISQUE AVANT ET À TAMBOUR ARRIÈRE.",
      imgUrl: "/models/j2c.png",
    },
    section2: {
      title: "TABLEAU DE BORD",
      description:
        "ÉCRAN LCD, VOLTMÈTRE, WATTMÈTRE, KILOMÉTRAGE ET CAMÉRA DE RECUL, PLUS BLUETOOTH, MP5, CONNECTEUR USB.CLIMATISEUR EN OPTION",
      imgUrl: "/models/j2c.png",
    },
    carDetails: {
      dimensions: "2890*1180*1780",
      maxAutonomy: "165/190",
      capacity: "1",
      emptyWeight: 500,
      loadingCapacity: 330,
      steeringMode: "Handle",
      motor: "3000W",
      battery: "LiFePo4 105Ah/206Ah",
      chargingTime: "2.5/5.5 heures (chargeur rapide)",
      charger: "Smart Charger",
      brakingSystemType: "système hydraulique",
      frontBrake: "Disque",
      rearBrake: "cylindre",
      frontSuspension: "Double triangulation indépendante",
      rearSuspension: "Axle arrière intégré",
      tire: "Avant 135/70-R12 Arrière 145/70-R12",
      wheelHub: "Moyeu en alliage d’aluminium",
      multimedia: "MP5 + Caméra de Recul",
      electricHeater: "60V 800W",
      centralLock: "Niveau Automatique",
      oneButtonStart: "Niveau Automatique",
      electricDoorAndWindow: 2,
      interior: "En Cuir",
    },
  },
  {
    model: "J2-D",
    brand: "PONI",
    category: "Véhicule électrique à quatre roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j2d/1.webp",
        alt: "j2",
      },
      {
        url: "/models/j2d/2.webp",
        alt: "j2",
      },
      {
        url: "/models/j2d/3.webp",
        alt: "j2",
      },
      {
        url: "/models/j2d/4.webp",
        alt: "j2",
      },
    ],
    section1: {
      title: "SYSTÈME DE CONTRÔLE ÉLECTRONIQUE",
      description:
        "UTILISEZ LE SYSTÈME DE CONTRÔLE ÉLECTRIQUE EMPOWER FIABLE ET ÉTANCHE. FREIN HYDRAULIQUE À DOUBLE CIRCUIT À DISQUE AVANT ET À TAMBOUR ARRIÈRE.",
      imgUrl: "/models/j2d.png",
    },
    section2: {
      title: "TABLEAU DE BORD",
      description:
        "ÉCRAN LCD, VOLTMÈTRE, WATTMÈTRE, KILOMÉTRAGE ET CAMÉRA DE RECUL, PLUS BLUETOOTH, MP5, CONNECTEUR USB.CLIMATISEUR EN OPTION",
      imgUrl: "/models/j2d.png",
    },
    carDetails: {
      dimensions: "2400*1260*1700",
      maxAutonomy: "100/190",
      capacity: "1",
      emptyWeight: 400,
      loadingCapacity: 220,
      steeringMode: "Handle",
      motor: "3000W",
      battery: "LiFePo4 105Ah/206Ah",
      chargingTime: "2.5/5.5 heures (chargeur rapide)",
      charger: "Smart Charger",
      brakingSystemType: "système hydraulique",
      frontBrake: "Disque",
      rearBrake: "cylindre",
      frontSuspension: "Double triangulation indépendante",
      rearSuspension: "Axle arrière intégré",
      tire: "Avant 120/70-R12 Arrière 145/70-R12",
      wheelHub: "Moyeu en alliage d’aluminium",
      multimedia: "MP5 + Caméra de Recul",
      electricHeater: "60V 800W",
      centralLock: "Niveau Automatique",
      oneButtonStart: "Niveau Automatique",
      electricDoorAndWindow: 2,
      interior: "En Cuir",
    },
  },
  {
    model: "J2-P",
    brand: "PONI",
    category: "Véhicule électrique à quatre roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j2p/1.webp",
        alt: "j2p",
      },
      {
        url: "/models/j2p/2.webp",
        alt: "j2p",
      },
      {
        url: "/models/j2p/3.webp",
        alt: "j2p",
      },
      {
        url: "/models/j2p/4.webp",
        alt: "j2p",
      },
      {
        url: "/models/j2p/5.webp",
        alt: "j2p",
      },
      {
        url: "/models/j2p/6.webp",
        alt: "j2p",
      },
    ],
    section1: {
      title: "SYSTÈME DE CONTRÔLE ÉLECTRONIQUE",
      description:
        "UTILISEZ LE SYSTÈME DE CONTRÔLE ÉLECTRIQUE EMPOWER FIABLE ET ÉTANCHE. FREIN HYDRAULIQUE À DOUBLE CIRCUIT À DISQUE AVANT ET À TAMBOUR ARRIÈRE.",
      imgUrl: "/models/j2p.png",
    },
    section2: {
      title: "TABLEAU DE BORD",
      description:
        "ÉCRAN LCD, VOLTMÈTRE, WATTMÈTRE, KILOMÉTRAGE ET CAMÉRA DE RECUL, PLUS BLUETOOTH, MP5, CONNECTEUR USB.CLIMATISEUR EN OPTION",
      imgUrl: "/models/j2p.png",
    },
    carDetails: {
      dimensions: "3600*1345*1765",
      maxAutonomy: "140/170",
      capacity: "1",
      emptyWeight: 510,
      loadingCapacity: 550,
      steeringMode: "Handle",
      motor: "4000W/5000W",
      battery: "LiFePo4 105Ah/206Ah",
      chargingTime: "2.5/5.5 heures (chargeur rapide)",
      charger: "Smart Charger",
      brakingSystemType: "système hydraulique",
      frontBrake: "Disque",
      rearBrake: "cylindre",
      frontSuspension: "Double triangulation indépendante",
      rearSuspension: "Axle arrière intégré",
      tire: "Avant 135/70-R12 Arrière 145/70-R12",
      wheelHub: "Moyeu en alliage d’aluminium",
      multimedia: "MP5 + Caméra de Recul",
      electricHeater: "60V 800W",
      centralLock: "Niveau Automatique",
      oneButtonStart: "Niveau Automatique",
      electricDoorAndWindow: 2,
      interior: "En Cuir",
    },
  },
  {
    model: "J2-W",
    brand: "PONI",
    category: "Véhicule électrique à quatre roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j2w/1.webp",
        alt: "j2p",
      },
      {
        url: "/models/j2w/2.webp",
        alt: "j2p",
      },
      {
        url: "/models/j2w/3.webp",
        alt: "j2p",
      },
    ],
    section1: {
      title: "SYSTÈME DE CONTRÔLE ÉLECTRONIQUE",
      description:
        "UTILISEZ LE SYSTÈME DE CONTRÔLE ÉLECTRIQUE EMPOWER FIABLE ET ÉTANCHE. FREIN HYDRAULIQUE À DOUBLE CIRCUIT À DISQUE AVANT ET À TAMBOUR ARRIÈRE.",
      imgUrl: "/models/j2p2.png",
    },
    section2: {
      title: "TABLEAU DE BORD",
      description:
        "ÉCRAN LCD, VOLTMÈTRE, WATTMÈTRE, KILOMÉTRAGE ET CAMÉRA DE RECUL, PLUS BLUETOOTH, MP5, CONNECTEUR USB.CLIMATISEUR EN OPTION",
      imgUrl: "/models/j2p2.png",
    },
    carDetails: {
      dimensions: "3600*1345*1765",
      maxAutonomy: "140/170",
      capacity: "1",
      emptyWeight: 510,
      loadingCapacity: 550,
      steeringMode: "Handle",
      motor: "4000W/5000W",
      battery: "LiFePo4 105Ah/206Ah",
      chargingTime: "2.5/5.5 heures (chargeur rapide)",
      charger: "Smart Charger",
      brakingSystemType: "système hydraulique",
      frontBrake: "Disque",
      rearBrake: "cylindre",
      frontSuspension: "Double triangulation indépendante",
      rearSuspension: "Axle arrière intégré",
      tire: "Avant 135/70-R12 Arrière 145/70-R12",
      wheelHub: "Moyeu en alliage d’aluminium",
      multimedia: "MP5 + Caméra de Recul",
      electricHeater: "60V 800W",
      centralLock: "Niveau Automatique",
      oneButtonStart: "Niveau Automatique",
      electricDoorAndWindow: 2,
      interior: "En Cuir",
    },
  },
  {
    model: "LANDING",
    brand: "PONI",
    category: "Véhicule électrique à trois roues",
    description: "Some information about the model",
    images: [
      {
        url: "/models/j1.webp",
        alt: "j1",
      },
      {
        url: "/models/j1c.webp",
        alt: "j1c",
      },
      {
        url: "/models/j1d.webp",
        alt: "j1",
      },
      {
        url: "/models/j2.webp",
        alt: "j1c",
      },
      {
        url: "/models/j2c.webp",
        alt: "j1c",
      },
      {
        url: "/models/j2d.webp",
        alt: "j1c",
      },
      {
        url: "/models/j2p.webp",
        alt: "j1c",
      },
      {
        url: "/models/j2p2.webp",
        alt: "j1c",
      },
    ],
  },
];

export const imageslanding = [
  {
    model: "LANDING",
    images: [
      {
        url: "/public/models/j1.webp",
        alt: "j1",
      },
      {
        url: "/public/models/j1c",
        alt: "j1c",
      },
      {
        url: "/public/models/j1.webp",
        alt: "j1",
      },
      {
        url: "/public/models/j1c",
        alt: "j1c",
      },
    ],
  },
];
