export const carDetailsLabels = {
  dimensions: "Dimensions (mm)",
  maxAutonomy: "Max. Autonomie (km)",
  capacity: "Capacité (persons)",
  emptyWeight: "Poids à vide (kg)",
  loadingCapacity: "Capacité de chargement (kg)",
  steeringMode: "Mode de direction",
  motor: "A/C Motor",
  battery: "Lithium Batterie",
  chargingTime: "Temps de Chargement",
  charger: "Charger",
  brakingSystemType: "Type du Système de Freinage",
  frontBrake: "Frein Avant",
  rearBrake: "Arein Arrière",
  frontSuspension: "Suspension Avant",
  rearSuspension: "Suspension Arrière",
  tire: "Pneu",
  wheelHub: "moyeu de roue",
  multimedia: "Multimédia",
  electricHeater: "Chauffage électrique",
  centralLock: "Verrouillage Centralisé",
  oneButtonStart: "Démarrage à un bouton",
  electricDoorAndWindow: "Porte et fenêtre électriques",
  interior: "Intérieur",
};
