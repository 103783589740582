import React, {useEffect, useLayoutEffect, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { cars } from "../assets/utils/carsObject";
import ImageSlider from "./ImageSlider";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/cardetails.css";
import { NavBarOnlyWithLogoAndPrevButton } from "./NavBarOnlyWithLogoAndPrevButton";
import ContactUs from "./ContactUs";
import Footer from "./Footer"

const CarDetails = () => {
  const { carModel } = useParams();
  const car = cars.find((c) => c.model === carModel);
  // Scroll to the top of the page when the component mounts
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  // Ref to track slide elements for animations
  const slideElements = useRef([]);
  // Handle element animations when they intersect
  useEffect(() => {
    let observer;
    const decideAnimationDirection = (index) =>
      index % 2 === 0 ? "slide-in-left" : "slide-in-right";

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const targetIndex = slideElements.current.indexOf(entry.target);
          entry.target.classList.add(decideAnimationDirection(targetIndex));
          observer.unobserve(entry.target);
        }
      });
    };

    observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4,
    });

    const slideElementsCurrent = slideElements.current; // Store the current ref value

    return () => {
      slideElementsCurrent.forEach((el) => {
        if (el instanceof Element) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  // Render a message if the car is not found
  if (!car) {
    return <div>Car not found!</div>;
  }

  return (
    <>
      <NavBarOnlyWithLogoAndPrevButton />
      <Container
        className="mb-1 header-container"
        ref={(el) => (slideElements.current[0] = el)}
      >
        <Row className="d-flex align-items-center justify-content-between">
          <Col className="text-dark-header">{car.model} Details</Col>
          <Col className="text-right">Home / {car.model}</Col>
        </Row>
      </Container>
      <Container
        className="content-container"
        ref={(el) => (slideElements.current[1] = el)}
      >
        <Row className="justify-content-md-center">
          <Col md="4" className="slider-col">
            <ImageSlider images={car.images} />
          </Col>
          <Col xs="8" className="info-col">
            <div className="box project-info-box mt-2">
              <h4 className="text-primary">information du projet</h4>
              <div className="mt-1">
                <h4 className="text-dark">Marque:</h4>
                <p className="text-muted">{car.brand}</p>
              </div>
              <div className="mt-1">
                <h5 className="text-dark">Categories:</h5>
                <p className="text-muted">{car.category}</p>
              </div>
              <div className="mt-1">
                <h5 className="text-dark">URL Projet:</h5>
                <p className="text-muted">www.ponicars.com</p>
              </div>
            </div>
            <div className="box model-info-box mt-1">
              <h4 className="text-primary">Model: {car.model}</h4>
              <Link to={`/${carModel}/configuration`}>
                <button className="btn btn-warning">Configuration Standard </button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <ContactUs />
      <Footer/>
    </>
  );
};

export default CarDetails;
