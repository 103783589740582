import React from "react";
import "../styles/compartement.css";
import { PiPlantDuotone } from "react-icons/pi";
import { GiCooler } from "react-icons/gi";
import { FcSmartphoneTablet, FcCrystalOscillator } from "react-icons/fc";
import { FaFire } from "react-icons/fa";
import {useScrollTriggerAnimation} from "../hooks/useScrollTriggerAnimation";

const Compartement = () => {

  const animFrom = { x: -200, opacity: 0 };
  const animTo = { x: 0, opacity: 1 };

  const ref = useScrollTriggerAnimation({
    from: animFrom,
    to: animTo
  });


  return (
      <div ref={ref} id="Compartement">
        <div className="comartement-container">
          <div className="comartement">
            <FcCrystalOscillator className="comartement-icon icon-1" />
            <h5>150 KM AVEC CHARGE COMPLÈTE</h5>
            <hr />
            <p>
              Avec une charge complète, distance de 150 km avec fonction de
              charge rapide 220V.
            </p>
          </div>
          <div className="comartement">
            <PiPlantDuotone className="comartement-icon icon-2" color="green" />
            <h5>ELECTRIQUE 100% ÉMULSION DE GAZ 0%</h5>
            <hr />
            <p>Respectueux de l'environnement avec une émulsion de gaz à 0%</p>
          </div>
          <div className="comartement">
            <GiCooler className="comartement-icon" color="blue" />
            <h5>REFROIDISSEMENT DE COFFRE DE CHARGEMENT</h5>
            <hr />
            <p>
              température de réfrigération de coffre de chargement réglable de
              -18 °C, -10 °C{" "}
            </p>{" "}
          </div>
          <div className="comartement">
            <FaFire className="comartement-icon icon-4" color="red" />
            <h5>CHAUFFAGE DE COFFRE DE CHARGEMENT</h5>
            <hr />
            <p>
              température de chauffage de coffre de chargement réglable de +40
              °C, +50 °C
            </p>
          </div>
          <div className="comartement">
            <FcSmartphoneTablet className="comartement-icon icon-1" />
            <h5>DEVERROUILLEZ ET VERROUILLEZ VIA PORTABLE</h5>
            <hr />
            <p className="">
              Code QR pour déverrouiller la voiture. Bouton de fin de trajet
              pour verrouiller
            </p>
          </div>
        </div>
      </div>
  );
};

export default Compartement;
