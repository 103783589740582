import { useCallback, useEffect, useRef, useState } from "react";
import "../styles/InfiniteLooper.css";

const InfiniteLooper = function InfiniteLooper({ speed, direction, children }) {
    // State to keep track of the number of looper instances
    const [looperInstances, setLooperInstances] = useState(1);
    const outerRef = useRef(null);
    const innerRef = useRef(null);

    // Determine the orientation based on the provided direction
    const orientation =
        direction === "up" || direction === "down" ? "vertical" : "horizontal";

    // Function to reset animation by toggling 'data-animate' attribute
    function resetAnimation() {
        if (innerRef?.current) {
            innerRef.current.setAttribute("data-animate", "false");

            setTimeout(() => {
                if (innerRef?.current) {
                    innerRef.current.setAttribute("data-animate", "true");
                }
            }, 10);
        }
    }

    // Function to set up instances of the looper based on available space
    const setupInstances = useCallback(() => {
        if (!innerRef?.current || !outerRef?.current) return;

        const { width, height } = innerRef.current.getBoundingClientRect();

        const { width: parentWidth, height: parentHeight } =
            outerRef.current.getBoundingClientRect();

        if (orientation === "vertical") {
            const heightDeficit = parentHeight - height;

            const instanceHeight = height / innerRef.current.children.length;

            if (heightDeficit) {
                setLooperInstances(
                    looperInstances +
                    Math.ceil(heightDeficit / instanceHeight) +
                    1
                );
            }
        } else {
            const widthDeficit = parentWidth - width;

            const instanceWidth = width / innerRef.current.children.length;
            if (widthDeficit) {
                setLooperInstances(
                    looperInstances +
                    Math.ceil(widthDeficit / instanceWidth) +
                    1
                );
            }
        }

        resetAnimation();
    }, [looperInstances, orientation]);

    useEffect(() => setupInstances(), [setupInstances]);

    // Add a resize event listener to recalculate instances when the window size changes
    useEffect(() => {
        window.addEventListener("resize", setupInstances);

        return () => {
            window.removeEventListener("resize", setupInstances);
        };
    }, [looperInstances, setupInstances]);

    return (
        <div className="looper" ref={outerRef} orientation={orientation}>
            <div
                className="looper__innerList"
                ref={innerRef}
                data-animate="true"
                orientation={orientation}
            >
                {[...Array(looperInstances)].map((_, ind) => (
                    <div
                        key={ind}
                        className="looper__listInstance"
                        style={{
                            animationDuration: `${speed}s`,
                            animationDirection:
                                direction === "right" || direction === "down"
                                    ? "reverse"
                                    : "normal",
                        }}
                    >
                        {children}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InfiniteLooper;
