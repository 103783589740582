import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import "../styles/contact.css";

function ContactUs() {
  return (
      <div className="contact-us">
        <h3 style={{
          marginBottom: "4rem",
          fontWeight: 500,
          color: "#000",
          letterSpacing: "0.2em",
          fontSize: "30px",
        }}>Follow Ponicars</h3>
        <div className="social-media">
          <Link to="https://www.facebook.com">
            <FaFacebook className="facebook-icon social-icons"  />
          </Link>
          <Link to="https://www.instagram.com">
            <FaInstagram className="instagram-icon social-icons" />
          </Link>
          <Link to="https://www.twitter.com">
            <FaXTwitter className="social-media-icon social-icons" />
          </Link>
          <Link to="https://www.youtube.com">
            <FaYoutube className="youtube-icon social-icons" />
          </Link>
          <Link to="https://www.linkedin.com">
            <FaLinkedin className="linkedin-icon social-icons" />
          </Link>
        </div>
      </div>
  );
}

export default ContactUs;
