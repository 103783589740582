import React from "react";
import "../styles/footer.css";
import {
    FaGlobe,
    FaPhone,
    FaEnvelope,
} from "react-icons/fa";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={"/models/logo-left.webp"} alt="Logo"/>
                    <h6>Vente et fabrication de véhicules Electriques</h6>
                </div>
                <div className="footer-info">
                    <p><FaGlobe className="icon" color="white"/> www.ponicars.com</p>
                    <p><FaEnvelope className="icon" color="white"/> INFO@PONIMAROC.COM</p>
                    <p><FaPhone className="icon" color="white"/> + 212 663 22 20 68</p>
                </div>
            </div>
            <div className="footer-bottom">
                &copy; {new Date().getFullYear()} Poni Maroc. Tous droits réservés.
            </div>
        </footer>
    );
}

export default Footer;
