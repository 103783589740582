import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/portfolio.css";
import { useNavigate } from "react-router-dom";

const carData = {
    "three-wheel": [
        { model: "J1", image: "/models/j1.webp", name: "J1" },
        { model: "J1-C", image: "/models/j1c.webp", name: "J1-C" },
        { model: "J1-D", image: "/models/j1d.webp", name: "J1-D" },
    ],
    "four-wheel": [
        { model: "J2", image: "/models/j2.webp", name: "Furia" },
        { model: "J2-C", image: "/models/j2c.webp", name: "Deli Max" },
        { model: "J2-D", image: "/models/j2d.webp", name: "Deli" },
        { model: "J2-P", image: "/models/j2p.webp", name: "City" },
        { model: "J2-W", image: "/models/j2w.webp", name: "J2-W" },
    ],
};

const Portfolio = () => {
    const [filter, setFilter] = useState("four-wheel");
    const navigate = useNavigate();

    const handleFilter = (type) => {
        setFilter(type);
    };

    const handleCarClick = (model) => {
        navigate(`/car-details/${model}`);
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            const container = document.querySelector(".portfolio .box-filter");
            if (!container) return;

            switch (event.key) {
                case "ArrowLeft":
                    container.scrollLeft -= 100;
                    break;
                case "ArrowRight":
                    container.scrollLeft += 100;
                    break;
                default:
                    break;
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    return (
        <div className="show-models" id="modeles">
            <div className="main-title">MODELES</div>
            <div className="filter-buttons">
                <button
                    className="btn mx-1 btn-outline-dark"
                    onClick={() => handleFilter("all")}
                >
                    tous
                </button>
                <button
                    className="btn mx-1 my-1 btn-outline-dark"
                    onClick={() => handleFilter("three-wheel")}
                >
                    VOITURE À TROIS ROUES
                </button>
                <button
                    className="btn mx-1 btn-outline-dark"
                    onClick={() => handleFilter("four-wheel")}
                >
                    VOITURE À QUATRE ROUES
                </button>
            </div>

            <div className="portfolio">
                {Object.keys(carData).map((key) =>
                        (filter === "all" || filter === key) && (
                            <div key={key} className={`portfolio-item ${key}`}>
                                <div className="box-filter d-flex">
                                    {carData[key].map((car) => (
                                        <div key={car.model} className="box" onClick={() => handleCarClick(car.model)}>
                                            <img src={car.image} alt={`Electric ${key} Car`} />
                                            <button className="btn btn-warning">{car.name}</button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                )}
            </div>
        </div>
    );
};

export default Portfolio;
