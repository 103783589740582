import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./pages/Homepage";
import CarDetails from "./components/CarDetails";
import React from "react";
import CarConfiguration from "./components/CarConfiguration";
import Pra from "./components/Pra";

function Layout() {
  const location = useLocation();

  return (
    <>
      <Routes key={location.pathname}>
        <Route path={"/"} element={<Homepage />} />
        <Route path={"/pra"} element={<Pra />} />
        <Route path={"/car-details/:carModel"} element={<CarDetails />} />
        <Route
          path={"/:carModel/configuration"}
          element={<CarConfiguration />}
        />
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}

export default App;
