import React from "react";
import "../styles/services.css";
import {FaCar, FaTools, FaWrench, FaCogs, FaComments} from "react-icons/fa";
import {useScrollTriggerAnimation} from "../hooks/useScrollTriggerAnimation"; // You can choose icons from react-icons library

const Services = () => {
    const animFrom = {x: -200, opacity: 0};
    const animTo = {x: 0, opacity: 1};

    const ref = useScrollTriggerAnimation({
        from: animFrom,
        to: animTo
    });
    return (
        <div ref={ref} className="all-services" id="services">
            <div className="main-title">SERVICES</div>
            <div className="services-container">
                <div className="service">
                    <FaCar className="service-icon" color="green"/>
                    <h3>Pré-ventes</h3>
                    <p>
                        Nous fournissons des conseils d’experts et des informations sur
                        nos voitures électriques avant l’achat
                    </p>
                </div>
                <div className="service">
                    <FaCogs className="service-icon" color="black"/>
                    <h3>Après vente</h3>
                    <p>
                        Notre accompagnement se poursuit après la vente avec maintenance
                        et assistance.
                    </p>
                </div>
                <div className="service">
                    <FaWrench className="service-icon" color="brown"/>
                    <h3>Réparation</h3>
                    <p>
                        Nous proposons des services de réparation pour assurer le bon
                        fonctionnement de votre voiture électrique.
                    </p>
                </div>
                <div className="service">
                    <FaTools className="service-icon" color="orange"/>
                    <h3>Installation</h3>
                    <p>
                        Nous pouvons vous aider à installer des équipements de recharge
                        pour voitures électriques.
                    </p>
                </div>
                <div className="service">
                    <FaCogs className="service-icon" color="red"/>
                    <h3>Vente de pièces automobiles</h3>
                    <p>
                        Nous fournissons des pièces et accessoires automobiles d'origine
                        pour votre véhicule électrique.
                    </p>
                </div>
                <div className="service">
                    <FaComments className="service-icon" color="olive"/>
                    <h3>Communication client</h3>
                    <p>
                        Nous maintenons une communication constante avec nos clients pour
                        leur satisfaction.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Services;
