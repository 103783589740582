import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const useScrollTriggerAnimation = ({ trigger, from, to, toggleActions, duration }) => {
    const ref = useRef(null);

    useEffect(() => {
        const element = ref.current;

        gsap.fromTo(element, {
            ...from
        }, {
            ...to,
            duration: duration || 1,
            scrollTrigger: {
                trigger: trigger || element,
                toggleActions: toggleActions || 'play none none none',
                start: 'top center+=100',
                end: 'bottom top',
            }
        });
    }, [trigger, from, to, toggleActions, duration]);

    return ref;
};
